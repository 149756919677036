<template>
  <div class="privacy-policy">
    <h1 class="title">
      POLÍTICA DE PRIVACIDADE
    </h1>
    <h2 class="subtitle">
      DAS INFORMAÇÕES GERAIS
    </h2>
    <p>
      Este documento visa informar o posicionamento do Procon Vitória quanto à
      privacidade dos dados pessoais por ventura
      tratados pela mesma, além de firmar o compromisso de estar em conformidade
      com a Lei Geral de Proteção de Dados. Aqui serão abordados os tratamentos
      dos dados pessoais, como sua coleta, finalidade, uso, armazenamento e
      proteção dos usuários do site, subserviços e aplicativos. Tem por
      finalidade a transparência dos serviços prestados e ampliação da
      acessibilidade ao cidadão por esta Instituição.
    </p>
    <p>
      Esta Política (Aviso) de Privacidade é destinada à todos os usuários e
      visitantes do site do PROCON VITÓRIA assim como em
      todas as aplicações em subdomínios e APP, integra os Termos e Condições
      Gerais de Uso
    </p>
    <p>
      Este documento foi elaborado em COMPLIANCE com a Lei Geral de Proteção de
      Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14)
      e a Lei de Acesso a Informação (Lei 12.527/11)
    </p>
    <h2 class="subtitle">
      COMO COLETAMOS OS DADOS PESSOAIS?
    </h2>
    <p>
      Os dados pessoais do usuário e visitante são recolhidos exclusivamente
      pela plataforma, conforme descrito a seguir, não utilizamos banco de dados
      comprados de terceiros.
    </p>
    <p>
      • Quando o usuário se cadastra no site: São dados de identificação, como:
      nome completo, e-mail, CPF, sexo, cartão do SUS, endereço, entre outros. A
      partir deles, podemos identificar o usuário e o visitante, para garantir a
      legitimidade do cidadão e uma maior segurança e bem-estar às suas
      necessidades.
    </p>
    <p>
      • Quando um usuário acessa as páginas do site: Estes dados contêm IPs de
      acesso, dentre outros necessárias as funcionalidades da página, como se vê
      na política de cookies.
    </p>
    <h2 class="subtitle">
      QUAIS DADOS PESSOAIS RECOLHEMOS.
    </h2>
    <p>Os dados pessoais dos usuários recolhidos são os seguintes:</p>
    <p>
      • nome completo, e-mail, CPF, RG, cartão do SUS, endereço, bairro CEP,
      cidade e todos dados solicitados para os diversos programas
    </p>
    <p>
      • Dados para otimização da navegação: Páginas de acesso, palavras-chave
      utilizadas na busca, endereço de IP e MAC.
    </p>
    <p>
      • Cookies, Push e Newsletter: Na abertura do APP ou dos sistemas o usuário
      pode optar em aceitar ou não a política de Cookies, em caso de recusa,
      salienta-se que algumas funcionalidades podem ser afetadas, os Push e
      Newsletter serão enviados somente com programas e assuntos do Procon Vitória.
    </p>
    <p>
      • O aplicativo em questão utiliza de uma tecnologia que realiza a
      conversão de áudio em texto, proporcionando uma experiência aprimorada ao
      usuário. Nesse processo, apenas o texto resultante é armazenado em nosso banco
      de dados, assegurando a confidencialidade e segurança dos dados dos usuários.
      Não há qualquer registro, compartilhamento ou utilização de gravações de áudio.
    </p>
    <h2 class="subtitle">
      FINALIDADES DOS DADOS PESSOAIS DOS USUÁRIOS?
    </h2>
    <p>
      Os dados coletados são requisitos necessários para o ingresso nos
      programas então obrigatórios.
    </p>
    <p>
      Os dados coletados não poderão ser repassados a terceiros excetos nos
      casos de convênios entre as agências de esfera Estadual ou Federal e na
      prestação de contas ou por ordens judiciais.
    </p>
    <p>
      Os dados pessoais coletados só poderão ser excluídos após o prazo legal
      estipulado por cada programa que o titular está participando
    </p>
    <h2 class="subtitle">
      DO ARMAZENAMENTO DOS DADOS PESSOAIS
    </h2>
    <p>
      Os dados pessoais do usuário e visitante são armazenados pela plataforma
      durante o período necessário para a prestação do serviço ou o cumprimento
      das finalidades previstas no presente documento, conforme o disposto no
      inciso I do artigo 15 da Lei Geral de Proteção de Dados 13.709/18, o Marco
      Civil da Internet (Lei 12.965/14) e a Lei de Acesso a Informação (Lei
      12.527/11)
    </p>
    <p>
      Os dados podem ser removidos ou anonimizados a pedido do usuário,
      excetuando os casos em que a lei oferecer outro tratamento.
    </p>
    <p>
      Ainda, os dados pessoais dos usuários apenas podem ser conservados após o
      término de seu tratamento nas seguintes hipóteses previstas no artigo 16
      da referida lei:
    </p>
    <p>I - Cumprimento de obrigação legal ou regulatória pelo controlador;</p>
    <p>
      II - Estudo por órgão de pesquisa, garantida, sempre que possível, a
      anonimização dos dados pessoais;
    </p>
    <p>
      III - Transferência a terceiro, desde que respeitados os requisitos de
      tratamento de dados dispostos nesta Lei;
    </p>
    <p>
      IV - Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde
      que anonimizados os dados.
    </p>
    <h2 class="subtitle">
      DA SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
    </h2>
    <p>
      O Procon Vitória se compromete a aplicar as medidas técnicas e organizativas aptas a
      proteger os dados pessoais de acessos não autorizados e de situações de
      destruição, perda, alteração, comunicação ou difusão de tais dados.
      Incluem-se ainda aqui, treinamentos para conscientização e capacitação da
      equipe dessa administração para o tratamento de dados dos titulares.
    </p>
    <p>
      O <b>Procon Vitória</b> não se exime de responsabilidade por culpa exclusiva de
      terceiros, como em caso de ataque de hackers ou crackers, mas sim por
      culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus
      dados a terceiros.
    </p>
    <p>
      O <b>Procon Vitória</b> se compromete a comunicar imediatamente o usuário em caso de
      alguma violação de segurança dos seus dados pessoais, conforme artigo 48.
    </p>
    <p>
      Os dados pessoais armazenados são tratados com confidencialidade para
      manter a inviolabilidade deles, dentro dos limites legais.
    </p>
    <h2 class="subtitle">
      DO COMPARTILHAMENTO DOS DADOS
    </h2>
    <p>
      O compartilhamento de dados ocorre quando obrigados pela lei para fazê-lo
      ou quando solicitado por outras esferas do poder público por meio de
      convênio.
    </p>
    <h2 class="subtitle">
      DA TRANSFERÊNCIA DE DADOS A TERCEIROS.
    </h2>
    <p>
      No caso de transferência internacional deverá possuir contrato com empresa
      sediada em países que possuam Lei de Privacidade de Dados.
    </p>
    <p>
      Ao acessar nossos serviços e aceitar nossa política, você está consentindo
      o processamento, transferência e armazenamento desta informação neste ou
      em outros países que também sejam amparados por legislação de proteção e
      privacidade de dados pessoais.
    </p>
    <h2 class="subtitle">
      POLÍTICA DE COOKIES OU DADOS DE NAVEGAÇÃO
    </h2>
    <p>
      Os cookies referem-se a arquivos de texto enviados ao computador do
      usuário em que nele ficam armazenados, com informações relacionadas à
      navegação site. Tais informações são relacionadas aos dados de acesso como
      local e horário de acesso, IP, MAC Address são armazenadas pelo navegador
      do usuário ou APP para que possa lê-las para garantir a legitimidade dos
      dados e atender a LEI 12.965 de 23 de abril de 2014.
    </p>
    <p>
      Após o usuário aceitar pode ser utilizado um sistema de coleta de dados de
      navegação mediante à utilização de cookies.
    </p>
    <p>
      O cookie persistente permanece no disco rígido do usuário depois que o
      navegador é fechado e será usado pelo navegador em visitas subsequentes ao
      site.
    </p>
    <p>
      Os cookies persistentes podem ser removidos seguindo as instruções do seu
      navegador.
    </p>
    <p>
      O cookie de sessão é temporário e desaparece depois que o navegador é
      fechado.
    </p>
    <p>
      É possível redefinir seu navegador da web para recusar todos os cookies,
      porém não sendo possível a validação a sessão não será ativa.
    </p>
    <h2 class="subtitle">
      DO CONSENTIMENTO
    </h2>
    <p>
      Ao realizar o cadastro o titular consentirá com a presente Política de
      Privacidade.
    </p>
    <p>
      O usuário tem direito à titularidade de seus dados pessoais (Artigo 17)
      bem como retirar o seu consentimento há qualquer tempo, e exercer seus
      direitos:
    </p>
    <p>• a confirmação da existência de tratamento;</p>
    <p>• o acesso aos dados mantidos pelo controlador;</p>
    <p>• a correção de dados incompletos, inexatos ou desatualizados;</p>
    <p>
      • a anonimização, bloqueio ou eliminação de dados, desde que sejam
      considerados desnecessários, excessivos ou tratados em desconformidade com
      o disposto na LGPD;
    </p>
    <p>
      • a portabilidade de seus dados pessoais a outro fornecedor de serviço;
    </p>
    <p>
      • a eliminação dos dados pessoais quando retirado o consentimento dado
      anteriormente;
    </p>
    <p>• a relação de com quem seus dados foram compartilhados;</p>
    <p>
      • a informação de que poderá negar consentimento e quais suas
      consequências;
    </p>
    <p>
      Quando uma decisão a respeito de seus dados pessoais é tomada com base em
      tratamento automatizado, o titular tem direito à revisão dessa decisão
      (artigo 20).
    </p>
    <p>
      Para tanto, disponibilizamos canal de atendimento através do e-mail
      dpocities@gmail.com. Ainda assiste à pessoa física o direito de peticionar
      contra os agentes de tratamento diretamente à Autoridade Nacional de
      Proteção de Dados, que exerce fiscalização e controle sobre aqueles
      (artigo 18, §1º).
    </p>
    <h2 class="subtitle">
      MODIFICAÇÕES DA POLÍTICA DE PRIVACIDADE
    </h2>
    <p>
      O Proprietário se reserva o direito de fazer alterações nesta política
      (aviso) de privacidade a qualquer momento, e informando ao titular através
      de notificação no site, e-mail ou push do aplicativo.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyProconVitoria',
  title: 'Procon Vitória - Política de Privacidade'
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  max-width: 100vw;
  padding: 1rem;

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .wrap {
    padding: 0 2rem;
  }

  h1 {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }

  .font-gray {
    color: $gray-500;
  }

  .text-center {
    text-align: center;
  }
}
</style>
